import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./styles/_index.scss";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import Loader from "./components/molecules/Loader";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter basename="/">
    <Suspense
      fallback={
        <div className="fixed w-full h-full flex justify-center items-center">
          <Loader />
        </div>
      }
    >
      <App />
    </Suspense>
  </BrowserRouter>
);

serviceWorkerRegistration.unregister();
