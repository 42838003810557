import React, { createContext, useReducer, useContext } from "react";

// Initial state
const initialState = null;

// Reducer function to handle state changes based on action
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...action.payload,
      };
    case "LOGOUT":
      return null;
    default:
      return state;
  }
};

// Create context
const UserContext = createContext({});

// Context provider component
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook to use user context
export const useUser = () => useContext(UserContext);
