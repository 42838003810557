import { lazy } from "react";
import FabricationFrançaise from "../views/SavoirFaire/Fabrication";

const MainLayout = lazy(() => import("../layouts/MainLayout"));

const Home = lazy(() => import("../views/home"));
const CataloguePiedLitCanape = lazy(() =>
  import("../views/CataloguePiedLitCanape")
);
const CatalogueAccessoire = lazy(() => import("../views/CatalogueAccessoires"));
const CatalogueComplements = lazy(() =>
  import("../views/CatalogueComplements")
);
const ProduitSimple = lazy(() => import("../views/ProduitSimple"));
const ProduitConfigurateur = lazy(() =>
  import("../views/ProduitConfigurateur")
);
const Configurator = lazy(() => import("../components/Configurator"));

const Faq = lazy(() => import("../views/Faq"));
const Contact = lazy(() => import("../views/Contact"));

const Actualites = lazy(() => import("../views/Actualites"));
const Actualite = lazy(() => import("../views/Actualites/Actualite"));

const SavoirFaire = lazy(() => import("../views/SavoirFaire"));
const Environnement = lazy(() => import("../views/SavoirFaire/Environnement"));
const SavoirFaire50 = lazy(() => import("../views/SavoirFaire/SavoirFaire-50"));
const ConceptArtisanal = lazy(() =>
  import("../views/SavoirFaire/ConceptArtisanal")
);

const Search = lazy(() => import("../views/Search"));
const Newsletter = lazy(() => import("../views/Newsletter"));
const Rappel = lazy(() => import("../views/Rappel"));
const Panier = lazy(() => import("../views/Panier"));
const Recapitulatif = lazy(() => import("../views/Recapitulatif"));
const NotFound = lazy(() => import("../views/NotFound"));
const Confirmation = lazy(() => import("../views/Confirmation"));
const LegalTerms = lazy(() => import("../views/LegalTerms"));
const PrivacyPolicy = lazy(() => import("../views/PrivacyPolicy"));
const Plansite = lazy(() => import("../views/Plansite"));

const MembersRoutes = {
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },

    {
      path: "pieds-de-lit-canape",
      element: <CataloguePiedLitCanape />,
    },
    // {
    //   path: "pieds-de-lit-canape/:slug",
    //   element: <Produit />,
    // },
    {
      path: "pieds-de-lit-canape/:slug",
      element: <ProduitConfigurateur />,
    },
    {
      path: "config",
      element: <Configurator />,
    },
    {
      path: "accessoires",
      element: <CatalogueAccessoire />,
    },
    {
      path: "accessoires/produit",
      element: <ProduitSimple />,
    },

    {
      path: "complements-literies",
      element: <CatalogueComplements />,
    },

    // Actualités
    {
      path: "/actualites",
      element: <Actualites />,
    },
    {
      path: "/actualites/:slug",
      element: <Actualite />,
    },

    // panier
    {
      path: "recapitulatif",
      element: <Recapitulatif />,
    },
    {
      path: "panier",
      element: <Panier />,
    },

    // contact
    {
      path: "contact",
      element: <Contact />,
    },

    // Rappel
    {
      path: "etre-rappele",
      element: <Rappel />,
    },

    // Newsletter
    {
      path: "newsletter",
      element: <Newsletter />,
    },

    // FAQ
    {
      path: "faq",
      element: <Faq />,
    },
    // Résultat de recherche
    {
      path: "recherches/:search",
      element: <Search />,
    },
    // Résultat de recherche
    {
      path: "la-societe-margot",
      element: <SavoirFaire />,
    },

    {
      path: "la-societe-margot/environnement",
      element: <Environnement />,
    },

    {
      path: "la-societe-margot/savoir-faire-français",
      element: <SavoirFaire50 />,
    },

    {
      path: "la-societe-margot/conception-artisanale",
      element: <ConceptArtisanal />,
    },

    {
      path: "la-societe-margot/fabrication-française",
      element: <FabricationFrançaise />,
    },

    {
      path: "configurateur",
      element: <Configurator />,
    },
    {
      path: "*",
      element: <NotFound />,
    },

    {
      path: "/confirmation",
      element: <Confirmation />,
    },

    {
      path: "/mentions-legales",
      element: <LegalTerms />,
    },
    {
      path: "/politique-de-confidentialite",
      element: <PrivacyPolicy />,
    },
    {
      path: "/plan-site",
      element: <Plansite />,
    },
  ],
};

export default MembersRoutes;
