import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getCookie, setCookie } from "./components/utils/CheckCookie";
import { CartProvider } from "./contexts/CartContext";
import { UserProvider } from "./contexts/User";
import Routes from "./routes";
import MetaTagsMaster from "./utils/MetaTagsMaster";

function App() {
  const location = useLocation();

  // Mise à jour de Google Consent Mode selon les préférences de l'utilisateur
  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    let cookieConsent;
    try {
      // Valeurs par défaut pour Google Consent Mode avant récupération du consentement
      gtag("consent", "default", {
        analytics_storage: "denied",
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        personalization_storage: "denied",
        functionality_storage: "granted", // Les fonctionnalités de base sont toujours activées
        security_storage: "granted", // Les fonctionnalités de sécurité sont toujours activées
      });

      // Tentative de récupération du consentement de l'utilisateur depuis le cookie
      cookieConsent = JSON.parse(getCookie("cookieConsent"));

      if (cookieConsent && process.env.NODE_ENV === "production") {
        // Mise à jour de Google Consent Mode avec les préférences de l'utilisateur
        gtag("consent", "update", {
          analytics_storage: cookieConsent.analytics_storage
            ? "granted"
            : "denied",
          ad_storage: cookieConsent.ad_storage ? "granted" : "denied",
          ad_user_data: cookieConsent.ad_user_data ? "granted" : "denied",
          ad_personalization: cookieConsent.ad_personalization
            ? "granted"
            : "denied",
          personalization_storage: cookieConsent.personalization_storage
            ? "granted"
            : "denied",
          functionality_storage: "granted", // Supposant que cela reste toujours activé
          security_storage: "granted", // Supposant que cela reste toujours activé
        });

        // Suivi de la pageview si le consentement pour les analytics est accordé
        if (cookieConsent.analytics_storage) {
          gtag("event", "pageview", {
            page_path: location.pathname + location.search,
          });
        }
      }
    } catch (error) {
      console.error("Error reading or updating cookie consent:", error);
      setCookie("cookieConsent", null, 0); // Réinitialisation du consentement en cas d'erreur
    }
  }, [location]);

  return (
    <UserProvider>
      <CartProvider>
        <MetaTagsMaster
          title={"Fabricant pieds de sommier – Margot Sa"}
          description={
            "Fabricant de pieds de sommiers depuis 1974. Chez nous l'essentiel est accessoire : pieds de lits, pieds de sommiers, encadrements, accessoires"
          }
          keywords={"Fabricant pieds de sommiers"}
        />
        <Routes />
      </CartProvider>
    </UserProvider>
  );
}

export default App;
