import React from "react";
import { Helmet } from "react-helmet";

export default function MetaTagsMaster({
  title,
  description,
  image,
  keywords,
  url,
  robots = true,
}) {
  const fullTitle = title
    ? `${title} - Margot Sa`
    : "Fabricant pieds de sommier - Margot Sa";
  const currentUrl = url ? url : window.location.href;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta
        name="robots"
        content={robots ? "index, follow" : "noindex, nofollow"}
      />
      {description && <meta name="description" content={description} />}
      <link rel="canonical" href={currentUrl} />
      {keywords && <meta name="keywords" content={keywords} />}

      {/* Open Graph / Facebook */}
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="MargotSa" />
      <meta property="og:title" content={fullTitle} />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      {/* Si vous souhaitez ajouter "twitter:site", décommentez la ligne suivante et remplacez la valeur. */}
      {/* <meta name="twitter:site" content="@Margot" /> */}
      <meta name="twitter:url" content={currentUrl} />
      <meta name="twitter:title" content={fullTitle} />
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
}
