import React from "react";
import { Link } from "react-router-dom";
import machinepieddelit from "../../assets/img/pied-lit-usine.webp";
import Insurance from "../../components/organisms/Insurance";
import MetaTagsMaster from "../../utils/MetaTagsMaster";

export default function FabricationFrançaise() {
  return (
    <div className="savoir-faire-page-inner">
      <MetaTagsMaster
        title={
          "Fabricant de pieds de lit et de sommier de haute qualité - Margot SA"
        }
        description={
          "Optez pour des pieds de lit et de sommier de haute qualité en hêtre massif avec Margot SA. Découvrez notre gamme de produits cylindriques et élégants."
        }
      />
      <section>
        <div className="container mx-auto">
          <div className="flex flex-row items-center justify-between">
            <div className="px-[57px] py-[64px] bg-white xl:max-w-[60%] lg:max-w-[80%] z-10 relative ml-[4%]">
              <Link
                title="Retour"
                to={"/la-societe-margot"}
                className="mb-[32px] px-4 py-2 bg-white text-black rounded-full border border-solid border-black uppercase FuturaMediumCd relative block w-fit"
              >
                Retour
              </Link>
              <p className="uppercase FuturaMedium mb-[24px]">
                Notre savoir-faire
              </p>
              <h1 className="text-[48px] FuturaMediumCd mb-[24px] max-w-[720px] leading-none">
                Fabricant français de pieds de sommier et pieds de lit sur
                mesure
              </h1>
              <p className="text-left text-[16px] FuturaBook w-[100%]">
                Depuis 1974, notre entreprise, basée à{" "}
                <strong>Champigné</strong> dans le{" "}
                <strong>Maine et Loire</strong>, est un acteur clé dans la
                fabrication française de <strong>pieds d'ameublement</strong>en
                bois. Que vous ayez besoin de pieds de lit, de canapé, de
                banquette ou de tout autre support en bois, nous fournissons des
                produits de haute qualité, fabriqués à partir de bois français
                certifié{" "}
                <strong>PEFC, exclusivement destinés aux professionnels</strong>
                .
              </p>
            </div>
          </div>
          <div className="mt-[-50px] bg-beigeopacity rounded-[20px]">
            <picture>
              <img
                className="rounded-[10px] w-full h-[444px] max-h-full object-cover min-h-full"
                src={machinepieddelit}
                alt="Machine industrielle pour fabriquer un pied de lit"
                loading="lazy"
                height={444}
                width={1440}
              />
            </picture>
            <div className="xl:px-[104px] py-[64px] lg:px-[64px] px-[64px]">
              <h2 className="text-[28px] bold FuturaBold pb-[24px]">
                Notre histoire sur les pieds de lits : fabriquer c'est notre
                métier
              </h2>
              <div className="flex lg:flex-row flex-col justify-between pt-[32px] gap-10">
                <div className="lg:w-[50%]">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Une entreprise familiale depuis 1974
                  </h3>
                  <p>
                    Depuis sa fondation en 1974, notre entreprise familiale,
                    située à <strong>Champigné</strong> dans le{" "}
                    <strong>Maine et Loire</strong>, est spécialisée dans la
                    fabrication de <strong>pieds d'ameublement</strong> en bois.
                    Nous sommes particulièrement reconnus comme{" "}
                    <strong>fabricant de pieds de lit</strong>. Notre engagement
                    envers la <strong>qualité</strong> et la{" "}
                    <strong>durabilité</strong> a permis de bâtir notre
                    réputation au fil des décennies.
                  </p>
                </div>
                <div className="lg:w-[50%]">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Notre évolution et nos innovations
                  </h3>
                  <p>
                    Au fil des ans, nous avons continuellement innové pour
                    répondre aux besoins changeants de nos clients. Nos
                    techniques de fabrication ont évolué, intégrant des méthodes
                    modernes tout en préservant l’artisanat traditionnel. Chaque
                    innovation vise à améliorer la <strong>durabilité</strong>{" "}
                    et l’esthétique de nos produits, tout en respectant l’
                    <strong>environnement</strong>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-[64px]">
        <div className="container mx-auto">
          <div className="bg-beigeopacity rounded-[20px]">
            <div className="xl:px-[104px] py-[64px] lg:px-[64px] px-[64px]">
              <h2 className="text-[28px] bold FuturaBold pb-[24px]">
                Notre Mission et Vision
              </h2>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Engagement envers la qualité et la durabilité
                  </h3>
                  <p>
                    Notre mission est de fournir des{" "}
                    <strong>pieds de lit en bois massif</strong>
                    de haute <strong>qualité</strong> qui répondent aux normes
                    les plus strictes de <strong>durabilité</strong>. Nous
                    croyons en la <strong>fabrication durable</strong> et en
                    l'utilisation de <strong>matériaux écologiques</strong>,
                    garantissant ainsi des produits qui durent dans le temps et
                    respectent notre planète.
                  </p>
                </div>
              </div>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Respect de l’environnement et éco-responsabilité
                  </h3>
                  <p>
                    Nous nous engageons à respecter{" "}
                    <strong>l'environnement</strong> à chaque étape de notre
                    processus de production. Nous utilisons du bois français
                    certifié <strong>PEFC</strong>, ce qui garantit une gestion
                    durable des forêts. Nos <strong>vernis écologiques</strong>{" "}
                    sont sans émission de COV, assurant des produits sains pour
                    nos clients et notre environnement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[64px]">
        <div className="container mx-auto">
          <div className="bg-beigeopacity rounded-[20px]">
            <div className="xl:px-[104px] py-[64px] lg:px-[64px] px-[64px]">
              <h2 className="text-[28px] bold FuturaBold pb-[24px]">
                Nos Produits fabriqués en France
              </h2>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Pieds de sommier en bois de hêtre
                  </h3>
                  <p>
                    Nos <strong>pieds de lit en bois</strong> sont conçus pour
                    apporter une touche de <strong>qualité</strong> et
                    d'élégance à vos meubles. Fabriqués à partir de bois de
                    hêtre massif français certifié <strong>PEFC</strong>, ils
                    combinent
                    <strong>durabilité</strong> et esthétique pour un résultat
                    incomparable.
                  </p>
                </div>
              </div>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Accessoires de mobilier et composants de couchage en literie
                  </h3>
                  <p>
                    En plus des <strong>pieds de lit</strong>, nous proposons
                    une gamme d'<strong>accessoires de mobilier</strong> et de
                    <strong>composants de literie</strong>. Chaque produit est
                    conçu avec le même souci de <strong>qualité</strong> et de
                    <strong>durabilité</strong>, répondant aux besoins des
                    <strong>professionnels de la literie</strong> et de l'
                    <strong>ameublement</strong>.
                  </p>
                </div>
              </div>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Éléments de canapé
                  </h3>
                  <p>
                    Nous fabriquons également des{" "}
                    <strong>de canapé en bois</strong>, alliant robustesse et
                    élégance. Nos produits sont parfaits pour les{" "}
                    <strong>fabricants de canapés</strong> cherchant des
                    composants fiables et esthétiques.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[64px]">
        <div className="container mx-auto">
          <div className="bg-beigeopacity rounded-[20px]">
            <div className="xl:px-[104px] py-[64px] lg:px-[64px] px-[64px]">
              <h2 className="text-[28px] bold FuturaBold pb-[24px]">
                Matériaux et Processus de Fabrication
              </h2>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Bois français certifié PEFC pour un pied de lit respectueux
                    de l'environnement
                  </h3>
                  <p>
                    Nous utilisons exclusivement du bois français certifié{" "}
                    <strong>PEFC</strong>
                    pour garantir une gestion durable des forêts. Ce choix
                    témoigne de notre engagement écologique et de notre désir de
                    soutenir les pratiques forestières responsables.
                  </p>
                </div>
              </div>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Utilisation de vernis écologique
                  </h3>
                  <p>
                    Pour répondre au mieux à la demande des professionnels de la
                    literie, notre production offre une diversité de coloris.
                    Ainsi, nos produits sont finis avec des{" "}
                    <strong>vernis écologiques</strong> à l'eau, sans émission
                    de COV. Cette finition assure non seulement la protection de
                    l'<strong>environnement</strong>, mais aussi la sécurité et
                    la santé de nos clients.
                  </p>
                </div>
              </div>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Variété et Flexibilité des Pieds de lits en bois
                  </h3>
                  <p>
                    Nous proposons également une vaste gamme de pieds de lit
                    pour répondre à tous les besoins.{" "}
                    <strong>
                      Vendus à l'unité ou en jeux de 4 pieds de lit
                    </strong>
                    , nos produits se déclinent en différentes formes telles que
                    des pieds de lit{" "}
                    <strong>
                      cylindriques, cubiques, coniques, techniques ou classiques
                    </strong>
                    . Vous trouverez des pieds de meuble avec des{" "}
                    <strong>diamètres et des hauteurs variés</strong>, 10 cm, 15
                    cm, 17 cm, 20 cm, 25 cm et 30 cm de hauteur, pour s'adapter
                    parfaitement à tous les sommiers et la décoration de votre
                    chambre.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[64px]">
        <div className="container mx-auto">
          <div className="bg-beigeopacity rounded-[20px]">
            <div className="xl:px-[104px] py-[64px] lg:px-[64px] px-[64px]">
              <h2 className="text-[28px] bold FuturaBold pb-[24px]">
                Notre Équipe
              </h2>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Experts en travail du bois
                  </h3>
                  <p>
                    Notre équipe est composée d'experts passionnés par le
                    travail du bois. Leur savoir-faire et leur dévouement sont
                    essentiels à la création de produits de haute{" "}
                    <strong>qualité</strong> qui répondent aux attentes de nos
                    clients et permet de leur offrir le{" "}
                    <strong>meilleur rapport qualité prix</strong>.
                  </p>
                </div>
              </div>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Passion et dévouement
                  </h3>
                  <p>
                    Chaque membre de notre équipe partage une passion pour le
                    bois et l’artisanat. Leur dévouement à produire des{" "}
                    <strong>pieds d’ameublement de qualité</strong> supérieure
                    est au cœur de notre succès.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[64px]">
        <div className="container mx-auto">
          <div className="bg-beigeopacity rounded-[20px]">
            <div className="xl:px-[104px] py-[64px] lg:px-[64px] px-[64px]">
              <h2 className="text-[28px] bold FuturaBold pb-[24px]">
                Engagements en matière de qualité
              </h2>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Produits durables et de haute qualité
                  </h3>
                  <p>
                    Nous nous engageons à fournir des produits durables et de
                    haute <strong>qualité</strong>. Chaque{" "}
                    <strong>pied de lit</strong> est fabriqué avec soin,
                    utilisant des <strong>matériaux</strong> sélectionnés pour
                    leur excellence et leur <strong>durabilité</strong>.
                  </p>
                </div>
              </div>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Respect des normes environnementales
                  </h3>
                  <p>
                    Nous respectons les normes environnementales les plus
                    strictes dans notre processus de fabrication. Nos
                    certifications <strong>PEFC</strong> et l'utilisation de
                    <strong>vernis écologiques</strong> témoignent de notre
                    engagement envers la protection de l'
                    <strong>environnement</strong>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[64px] pb-[64px]">
        <div className="container mx-auto">
          <div className="bg-beigeopacity rounded-[20px]">
            <div className="xl:px-[104px] py-[64px] lg:px-[64px] px-[64px]">
              <h2 className="text-[28px] bold FuturaBold pb-[24px]">
                Partenariats et Certifications
              </h2>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Collaboration avec les professionnels de la literie
                  </h3>
                  <p>
                    Nous travaillons en étroite collaboration avec des
                    <strong>professionnels de la literie</strong>, des
                    <strong>fabricants de meubles</strong> et de{" "}
                    <strong>canapés</strong>. Nos partenariats nous permettent
                    de répondre aux besoins spécifiques de nos clients en termes
                    de sélection de pieds et de fournir des solutions d’
                    <strong>ameublement intérieur</strong> de{" "}
                    <strong>qualité</strong>
                    supérieure.
                  </p>
                </div>
              </div>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Membres du collectif Parlons Literie
                  </h3>
                  <p>
                    En tant que membres du collectif Parlons Literie depuis
                    2023, nous participons activement à l'amélioration et à la
                    promotion de l'industrie de la literie en France.
                  </p>
                </div>
              </div>
              <div className="pt-[32px]">
                <div className="">
                  <h3 className="text-[20px] bold FuturaBold pb-[24px]">
                    Certifications PEFC
                  </h3>
                  <p>
                    Nos certifications PEFC garantissent que nos matériaux
                    proviennent de sources durables et responsables. Ce label de
                    qualité est un gage de notre engagement écologique et de
                    notre respect de l’environnement. Ainsi, le bois utilisé
                    pour la fabrication répond à ces mêmes critères stricts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Insurance />
    </div>
  );
}
