import React from "react";
import coq from "../../assets/img/coq_france.svg";
import camion from "../../assets/img/camion_livraison.svg";
import recycling from "../../assets/img/reclyclage_environnement.svg";
import sav from "../../assets/img/sav_client.svg";

export default function Insurance() {
  return (
    <div className="bg-white xl:pt-[32px] xl:pb-[32px] py-[32px]">
      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 w-full items-center container mx-auto">
        <div className="flex w-full items-center gap-4 md:flex-row flex-col">
          <img
            src={coq}
            alt="Coq Français"
            loading="lazy"
            width={47}
            height={51}
          />
          <div className="text-center md:text-left">
            <p className="text-base font-bold mb-[0px] FuturaBold">
              Fabrication 100% française
            </p>
            <p className="text-sm mb-[0]">qualité garantie</p>
          </div>
        </div>
        <div className="flex w-full items-center gap-4 md:flex-row flex-col">
          <img
            src={camion}
            alt="Camion de livraison rapide"
            loading="lazy"
            width={51}
            height={31}
          />
          <div className="text-center md:text-left">
            <p className="text-base font-bold mb-[0px] FuturaBold">
              Livraison rapide
            </p>
            <p className="text-sm mb-[0]">expédition en 48h</p>
          </div>
        </div>
        <div className="flex w-full items-center gap-4 md:flex-row flex-col">
          <img
            src={recycling}
            alt="Respect de l'environnement"
            loading="lazy"
            width={47}
            height={45}
          />
          <div className="text-center md:text-left">
            <p className="text-base font-bold mb-[0px] FuturaBold">
              Respect de l’environnement
            </p>
            <p className="text-sm mb-[0]">99% de matériaux recyclés</p>
          </div>
        </div>
        <div className="flex w-full items-center gap-4 md:flex-row flex-col">
          <img
            src={sav}
            alt="Service client"
            loading="lazy"
            width={43}
            height={43}
          />
          <div className="text-center md:text-left">
            <p className="text-base font-bold mb-[0px] FuturaBold">
              Toujours à votre écoute
            </p>
            <p className="text-sm mb-[0]">99% de nos clients satisfaits</p>
          </div>
        </div>
      </div>
    </div>
  );
}
